var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-8 py-4"},[_c('v-row',[_c('v-col',{staticClass:"py-4 px-4",attrs:{"cols":"12","sm":"6","lg":"5"}},[_c('v-layout',{staticClass:"text-sm-left",attrs:{"align-center":"","fill-height":""}},[(_vm.logo)?_c('img',{attrs:{"id":"logoLogin","src":_vm.serverUrl + '/images/' + _vm.logo + '?token=' + _vm.userToken}}):_c('img',{attrs:{"id":"logoLogin","src":_vm.serverUrl +
            '/images/' +
            'logo-pos.svg' +
            '?token=' +
            _vm.userToken +
            '&v=' +
            _vm.getDate(),"onerror":"this.onerror=null;this.src=serverUrl +'/images/' + 'logo-pos.png'+ '?token=' + userToken+ '&v=' + getDate();"}})])],1),_c('v-col',{staticClass:"py-4 px-4",attrs:{"cols":"12","sm":"6","lg":"7"}},[_c('languageSelector',{attrs:{"addContainer":true}}),_c('br'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendResetLink.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":_vm.$t('Email'),"required":"","validate-on-blur":"","rules":[
            function (v) { return !!v || _vm.$t('Email is required'); },
            function (v) { return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              _vm.$t('Email must be valid'); } ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-0 mr-0",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(_vm._s(_vm.$t("Cancel")))],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-0 mr-0",attrs:{"type":"submit","loading":_vm.loading,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Send reset link")))])],1)],1),(_vm.success.length > 0)?_c('errorHandeling',{attrs:{"buttons":[
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            } ],"snackbarText":_vm.success,"snackbarColor":"success","snackbarTimout":"-1","snackbarIcon":"mdi-check"},on:{"clearError":function($event){_vm.success = ''}}}):_vm._e(),(_vm.error)?_c('errorHandeling',{attrs:{"snackbarText":_vm.error,"buttons":[
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            } ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert"},on:{"clearError":function($event){_vm.error = ''}}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }