<template>
  <div>
    <v-card class="px-8 py-4" v-if="showCard">
      <languageSelector :addContainer="true" />

      <br />

      <template v-if="email.length > 0">
        <p v-html="$t('Reset password requested', { email: email })" />

        <v-form @submit.prevent="resetPassword" ref="form" lazy-validation>
          <v-text-field
            :label="$t('Password')"
            v-model="pass"
            type="password"
            required
            validate-on-blur
            :rules="[
              (v) => !!v || $t('Password is required'),
              (v) => (v && v.length > 4) || $t('Password is too short'),
            ]"
          ></v-text-field>

          <v-text-field
            :label="$t('Repeat password')"
            v-model="repeatPass"
            type="password"
            required
            validate-on-blur
            :rules="[
              (v) => !!v || $t('Password is required'),
              (v) => (v && v.length > 4) || $t('Password is too short'),
            ]"
          ></v-text-field>

          <div class="text-sm-right">
            <v-btn
              class="ml-0 mr-0"
              type="submit"
              :loading="loading"
              color="primary"
              >{{ $t("Reset password") }}</v-btn
            >
          </div>
        </v-form>
      </template>
      <template v-else>
        <preloader></preloader>
      </template>
    </v-card>
    <errorHandeling
      v-if="success.length > 0"
      :snackbarText="success"
      :buttons="[
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      snackbarColor="success"
      snackbarTimout="-1"
      snackbarIcon="mdi-check"
      @clearError="success = ''"
    />
    <errorHandeling
      v-if="error"
      :snackbarText="error"
      :buttons="[
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-alert"
      @clearError="$store.dispatch('resetError')"
    />
  </div>
</template>

<script>
import request from "../../request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      success: "",
      email: "",
      pass: "",
      repeatPass: "",
      loading: false,
      showCard: true,
    };
  },
  created() {
    this.getResetPasswordEmail();
  },
  computed: {
    ...mapGetters({
      error: "error",
    }),
  },
  methods: {
    getResetPasswordEmail() {
      this.success = "";

      request.get(
        "/resetpasswordemail/" + this.$route.params.hash,
        null,
        (res) => {
          if (res.success) {
            this.$store.dispatch("resetError");
            this.email = res.data;
          } else {
            this.showCard = false;
            this.$store.dispatch("setError", res);
          }
        }
      );
    },
    resetPassword() {
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        request.post(
          "/resetpassword/" + this.$route.params.hash,
          {
            email: this.email,
            password: this.pass,
            passwordrepeat: this.repeatPass,
          },
          (res) => {
            this.loading = false;

            if (res.success) {
              this.success = res.message;
              this.$store.dispatch("resetError");

              setTimeout(() => {
                this.$router.push({ name: "login" });
                this.$router.go(1);
              }, this.$config.hideMessageTimeout);
            } else {
              this.$store.dispatch("setError", res);
            }
          }
        );
      }
    },
  },
};
</script>

<style>
</style>
