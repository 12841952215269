var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('backButton'),(_vm.loading && !_vm.$store.getters.error)?[_c('preloader')]:[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("General")))]),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('Email'),"rules":[
                  function (v) { return !!v || _vm.$t('Email is required'); },
                  function (v) { return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    _vm.$t('Email must be valid'); } ],"required":"","validate-on-blur":""},model:{value:(_vm.user.Email),callback:function ($$v) {_vm.$set(_vm.user, "Email", $$v)},expression:"user.Email"}}),_c('v-text-field',{attrs:{"label":_vm.$t('First name'),"required":"","rules":[
                  function (v) { return !!v || _vm.$t('First name is required'); },
                  function (v) { return v.length >= 2 ||
                    _vm.$t('Minimum required characters is') + ' 2'; } ],"validate-on-blur":""},model:{value:(_vm.user.FirstName),callback:function ($$v) {_vm.$set(_vm.user, "FirstName", $$v)},expression:"user.FirstName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('Last name'),"required":"","rules":[
                  function (v) { return !!v || _vm.$t('Last name is required'); },
                  function (v) { return v.length >= 2 ||
                    _vm.$t('Minimum required characters is') + ' 2'; } ],"validate-on-blur":""},model:{value:(_vm.user.LastName),callback:function ($$v) {_vm.$set(_vm.user, "LastName", $$v)},expression:"user.LastName"}}),_c('v-switch',{attrs:{"label":_vm.$t('Administrator')},model:{value:(_vm.user.Administrator),callback:function ($$v) {_vm.$set(_vm.user, "Administrator", $$v)},expression:"user.Administrator"}}),_c('v-select',{attrs:{"items":_vm.languages,"label":_vm.$t('Select a language'),"item-value":"Language","item-text":"Language","required":"","rules":[function (v) { return !!v || _vm.$t('You must select an item'); }],"validate-on-blur":""},model:{value:(_vm.user.Language),callback:function ($$v) {_vm.$set(_vm.user, "Language", $$v)},expression:"user.Language"}}),_c('v-btn',{staticClass:"mb-3 ml-0 edit-page-action-button",attrs:{"color":"blue","loading":_vm.resendingMail,"dark":""},on:{"click":function($event){return _vm.sendWelcomeEmail()}}},[_vm._v(" "+_vm._s(_vm.$t("Resend welcome email"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-email")])],1)],1),(_vm.welcomeMailSuccess.length > 0)?_c('errorHandeling',{attrs:{"snackbarText":_vm.welcomeMailSuccess,"buttons":[
                {
                  isText: true,
                  functionName: 'clearError',
                  text: 'Close',
                } ],"snackbarColor":"success","snackbarTimout":"-1","snackbarIcon":"mdi-alert-circle"},on:{"clearError":function($event){_vm.welcomeMailSuccess = ''}}}):_vm._e()],1),_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Modules")))]),_c('v-card',[_c('v-card-text',[_vm._l((_vm.user.Websites),function(website,index){return [_c('v-layout',{key:index,attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs9":"","lg11":""}},[_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.getWebsiteNameFromId(website.$oid))+" ")])]),_c('v-flex',{staticClass:"text-right",attrs:{"xs3":"","lg1":""}},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.removeUserWebsite(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)]}),(_vm.getWebsitesNotAssigned().length > 0)?_c('v-select',{staticClass:"add-item-select mr-3",attrs:{"items":_vm.getWebsitesNotAssigned(),"label":_vm.$t('Select a module'),"item-value":"_id.$oid","item-text":"Name","single-line":""},model:{value:(_vm.websiteToAdd),callback:function ($$v) {_vm.websiteToAdd=$$v},expression:"websiteToAdd"}}):_vm._e(),(_vm.getWebsitesNotAssigned().length > 0)?_c('v-btn',{staticClass:"mt-3 edit-page-action-button dropdown-action-button",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.addUserWebsite()}}},[_vm._v(" "+_vm._s(_vm.$t("Add module"))+" "),_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],2)],1)],1)],1)],1),_c('cancelConfirmButtons',{attrs:{"confirmFunction":_vm.saveUser,"loading":_vm.saving,"confirmLabel":_vm.$t('Save')},on:{"goBack":_vm.goBack}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }