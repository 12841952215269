var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showCard)?_c('v-card',{staticClass:"px-8 py-4"},[_c('languageSelector',{attrs:{"addContainer":true}}),_c('br'),(_vm.email.length > 0)?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('Activate account enter your', { email: _vm.email }))}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.activateAccount.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":_vm.$t('Password'),"type":"password","required":"","validate-on-blur":"","rules":[
            function (v) { return !!v || _vm.$t('Password is required'); },
            function (v) { return (v && v.length > 4) || _vm.$t('Password is too short'); } ]},model:{value:(_vm.pass),callback:function ($$v) {_vm.pass=$$v},expression:"pass"}}),_c('v-text-field',{attrs:{"label":_vm.$t('Repeat password'),"type":"password","required":"","validate-on-blur":"","rules":[
            function (v) { return !!v || _vm.$t('Password is required'); },
            function (v) { return (v && v.length > 4) || _vm.$t('Password is too short'); } ]},model:{value:(_vm.repeatPass),callback:function ($$v) {_vm.repeatPass=$$v},expression:"repeatPass"}}),_c('div',{staticClass:"text-sm-right"},[_c('v-btn',{staticClass:"ml-0 mr-0",attrs:{"type":"submit","loading":_vm.loading,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Activate account")))])],1)],1)]:[_c('preloader')]],2):_vm._e(),(_vm.success.length > 0)?_c('errorHandeling',{attrs:{"snackbarText":_vm.success,"buttons":[
      {
        isText: true,
        functionName: 'clearError',
        text: 'Close',
      } ],"snackbarColor":"success","snackbarTimout":"-1","snackbarIcon":"mdi-check"},on:{"clearError":function($event){_vm.success = ''}}}):_vm._e(),(_vm.error)?_c('errorHandeling',{attrs:{"snackbarText":_vm.error,"buttons":[
      {
        isText: true,
        functionName: 'clearError',
        text: 'Close',
      } ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert"},on:{"clearError":function($event){return _vm.$store.dispatch('resetError')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }